.doccure_forgot_password_main_division {
    display: flex;
    width: 100%;
    padding: 40px 150px;
}

.doccure_forgot_password_main_first_division {
    width: 50%;
}

.doccure_forgot_password_img_division {
    width: 100%;
}

.doccure_forgot_password_img {
    width: 100%;
}

.doccure_forgot_password_main_second_division {
    width: 50%;
    height: max-content;
    border: 1px solid var(--doctor-booking-border);
    padding: 30px;
    border-radius: 3px;
    margin: 30px;
}

.doccure_forgot_password_title_name {
    font-size: var(--title-font);
    font-weight: 600;
}

.doccure_forgot_password_sub_title_name {
    font-size: var(--theme-description);
    color: var(--theme-table-color);
}

.doccure_forgot_password_email_field:focus {
    box-shadow: none;
    border-color: var(--theme-main-color) !important;
}

.doccure_forgot_password_email_field {
    height: 10% !important;
}

.doccure_forgot_password_email_field_division>label {
    color: var(--theme-table-color);
    font-size: var(--theme-font);
}

.doccure_forgot_password_forgot_password_division {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    font-size: var(--theme-description);
    color: var(--theme-main-color);
    cursor: pointer;
}

.doccure_forgot_password_btn_login_division {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    font-size: var(--title-font);
    border: none;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    border-radius: 5px;
}

.doccure_forgot_password_btn_login_division_inactive {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    font-size: var(--title-font);
    border: none;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    border-radius: 5px;
    border: 2px solid var(--theme-main-color);
}

.doccure_forgot_password_btn_login_division:hover {
    background-color: var(--theme-main-color);
}

.doccure_login_forgot_password_division {
    color: var(--theme-main-color);
}


@media only screen and (max-width:768px) {

    .doccure_forgot_password_main_division {
        padding: 0px;
        margin-top: 20px;
    }

}

@media only screen and (max-width:430px) {

    .doccure_forgot_password_main_division {
        display: block;
    }

    .doccure_forgot_password_main_first_division {
        display: none;
    }

    .doccure_forgot_password_main_second_division {
        width: 100%;
        margin: 0px;
    }
}