.client_register_main_division {
  display: flex;
  width: 100%;
  padding: 0px 100px;
}

.client_register_main_first_division {
  width: 50%;
}

.client_register_img_division {
  width: 100%;
}

.client_register_img {
  width: 100%;
}

.client_register_main_second_division {
  width: 50%;
  border: 1px solid var(--doctor-booking-border);
  padding: 30px;
  border-radius: 3px;
}

.client_register_title_main_division {
  display: flex;
}

.client_register_title_name {
  font-size: var(--title-font);
  font-weight: 600;
  width: 70%;
}








.client_register_forgot_password_division {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  font-size: var(--theme-description);
  color: var(--theme-main-color);
  cursor: pointer;
}


.client_register_btn_login_division_active {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  font-size: var(--theme-title-font);
  border: none;
  background-color: var(--theme-main-color);
  color: var(--secondary-color);
  border-radius: 5px;
}

.client_register_btn_login_division_active:hover {
  background-color: var(--theme-main-color);
}


.client_register_btn_login_division {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  font-size: var(--theme-title-font);
  border: none;
  background-color: var(--theme-table-color);
  color: var(--secondary-color);
  border-radius: 6px;
}



@media only screen and (max-width:768px) {

  .header_login_signup_btn_division {
    padding: 0px;
  }

  .header_main_division {
    padding-top: 8px;
  }

  .header_login_signup_btn_division {
    padding: 0px;
  }

  .client_register_main_division {
    padding: 0px;
  }
}

@media only screen and (max-width:576px) {

  .header_login_signup_btn_division {
    justify-content: center;
  }

  .client_register_main_second_division {
    width: 100%;
  }


  .header_login_signup_btn_division {
    justify-content: center;
  }

  .client_register_main_division {
    display: block;
  }

  .client_register_main_second_division,
  .client_register_main_first_division {
    width: 100%;
  }

}