/* breadcrumb_css_start */

.mitwank_newsletter_breadcrumb_main_division {
    background-color: var(--black-color);
    padding: 10px 30px;
}

.mitwank_newsletter_breadcrumb_division {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.mitwank_newsletter_breadcrumb_title {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    text-transform: capitalize;
    margin-bottom: 0px;
}

.mitwank_newsletter_breadcrumb_title1 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    margin: 0px 5px;
    text-transform: capitalize;
}

.mitwank_newsletter_breadcrumb_title2 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    text-transform: capitalize;
    margin-bottom: 0px;
}

.mitwank_newsletter_breadcrumb_title_main {
    color: var(--secondary-color);
    font-size: var(--theme-title-font);
    font-weight: 700;
    text-transform: capitalize;
}

/* breadcrumb_css_end */


.mitwank_newsletter_main_diviison{
    border-radius: 5px;
    margin-bottom: 20px;
}

.export_button {
    width: 100px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--theme-main-color) !important;
    color: var(--secondary-color) !important;
}

.mitwank_newslettter_table_main_division {
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
  }