.header_main_division {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    border-bottom: .5px solid var(--doctor-booking-second-border);
}

.header_logo_main_division {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 30%;
}


.header_logo_division {
    width: 60%;
}

@media only screen and (max-width:768px) {
    .header_logo_division {
        width: 110%;
        height: 100%;
    }
}

.header_menu_main_division {
    display: flex;
    align-items: center;
}

.header_menu_division_active {
    text-transform: capitalize;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 700;
    color: var(--theme-main-color);
}

.header_menu_division {
    text-transform: capitalize;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 700;
}

.header_menu {
    position: absolute;
    background-color: var(--secondary-color);
    top: 70px;
    width: max-content;
    color: var(--secondary-color);
    border-radius: 5px;
    box-shadow: 0 0 3px var(--doc-header-bs);
}

.header_menu::before {
    content: "";
    position: absolute;
    top: 2px;
    right: 90px;
    border: 7px solid var(--secondary-color);
    border-color: transparent transparent var(--secondary-color) var(--secondary-color);
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 2px 2px -1px var(--doc-header-bs);
}

.header_menu_name {
    color: var(--black-color);
    text-transform: capitalize;
    padding: 5px 15px;
    border-bottom: 1px solid var(--doctor-booking-border);
    cursor: pointer;
}

.header_center_main_division {
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.clock_div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.header_center_main_division_1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:576px) {

    .header_center_main_division_1 {
        display: none;
    }
}

@media only screen and (max-width:1440px) {
    .header_center_main_division {
        margin-right: 30px;
    }
}

@media only screen and (max-width:1024px) {

    .header_center_main_division {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width:768px) {
    .header_center_main_division {
        display: none;
    }
}

.header_center_division_active {
    font-size: var(--theme-title-main);
    text-transform: capitalize;
    font-weight: 600;
    color: var(--theme-main-color);
    cursor: pointer;
    margin-left: 60px;
    letter-spacing: 0.02em;
}

.header_center_division {
    font-size: var(--theme-title-main);
    text-transform: capitalize;
    font-weight: 500;
    color: var(--theme-color-black);
    cursor: pointer;
    margin-left: 60px;
    letter-spacing: 0.02em;
    white-space: nowrap;
}

@media only screen and (max-width:768px) {

    .header_center_division {
        font-size: var(--theme-title-main);
    }

}

.header_center_division:hover {
    color: var(--theme-main-color);
    border-bottom: 1px solid var(--theme-main-color);
    transform: translate(1px);
}












.header_profile_arrow_up_icon {
    transition: .3s;
}















/* login_signup_button */

.header_login_signup_btn_division {
    display: flex;
    align-items: center;
    justify-content: end;
}

.header_login_signup_btn {
    padding: 10px 15px;
    color: var(--secondary-color);
    background-color: var(--theme-main-color);
    font-weight: 600;
}

.header_login_signup_btn:hover {
    color: var(--secondary-color);
}






/* header_mobile_view start */



.header_main_division_mobile {
    display: flex;
    align-items: center;
    padding: 10px;
}



@media only screen and (min-width:768px) {

    .header_logo_division_mobile {
        width: 25% !important;
    }
}

.header_logo_main_division_mobile {
    display: flex;
    justify-content: center;
}

.header_logo_division_mobile {
    width: 50%;

}

.header_menu_main_division_mobile {
    display: flex;
    align-items: center;
}

.header_menu_division_active_mobile {
    text-transform: capitalize;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 700;
    color: var(--theme-main-color);
}

.header_menu_division_mobile {
    text-transform: capitalize;
    margin-right: 20px;
    cursor: pointer;
    font-weight: 700;
}

.header_menu_mobile {
    position: absolute;
    background-color: var(--secondary-color);
    top: 70px;
    width: max-content;
    color: var(--secondary-color);
    border-radius: 5px;
    box-shadow: 0 0 3px var(--doc-header-bs);
}

.header_menu_mobile::before {
    content: "";
    position: absolute;
    top: 2px;
    right: 90px;
    border: 7px solid var(--secondary-color);
    border-color: transparent transparent var(--secondary-color) var(--secondary-color);
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 2px 2px -1px var(--doc-header-bs);
}

.header_menu_name_mobile {
    color: var(--black-color);
    text-transform: capitalize;
    padding: 5px 15px;
    border-bottom: 1px solid var(--doctor-booking-border);
    cursor: pointer;
}


.header_center_division_active_mobile {
    font-size: var(--title-font);
    text-transform: capitalize;
    font-weight: 500;
    color: var(--theme-main-color);
    cursor: pointer;
    margin-left: 10px;
}

.header_center_division_mobile {
    font-size: var(--title-font);
    text-transform: capitalize;
    font-weight: 500;
    color: var(--theme-main-color);
    cursor: pointer;
    margin-left: 10px;
}


.header_center_division_mobile:hover {
    color: var(--theme-main-color);
}

.header_contact_profile_main_division_mobile {
    display: flex;
    align-items: center;
    justify-content: end;
    /* padding: 0px 50px 0px 0px; */
    width: 20%;
}



.header_contact_main_division_mobile {
    display: flex;
    align-items: center;
}

.header_contact_icon_mobile {
    color: var(--theme-table-color);
    font-size: var(--main-title-large);
}

.header_contact_division_mobile {
    margin-left: 10px;
}

.header_contact_title_mobile {
    margin-bottom: 0px;
    color: var(--theme-table-color);
}

.header_contact_number_mobile {
    font-weight: 700;
    margin-bottom: 0px;

}

.header_profile_main_division_mobile {
    display: flex;
    align-items: center;
    margin-left: 30px;

}

.header_profile_image_division_mobile {
    width: 30px;
    height: 30px;
    margin: 0px 5px;
}

.header_profile_image_mobile {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.header_profile_arrow_up_icon_mobile {
    transition: .3s;
}

.header_profile_division_mobile {
    position: absolute;
    top: 70px;
    right: 20px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    box-shadow: 0 0 3px var(--doc-header-bs);
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -ms-transform: translateY(0);
    transform: translateY(0);
    z-index: 1;
}



.header_profile_division_mobile::before {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    border: 7px solid var(--secondary-color);
    border-color: transparent transparent var(--secondary-color) var(--secondary-color);
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -2px 2px 2px -1px var(--doc-header-bs);
}

.header_profile_division_header_mobile {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid var(--doctor-booking-border);
}

.header_profile_header_profile_image_division_mobile {
    width: 35px;
    height: 35px;
    margin: 0px 5px;
}

.header_profile_header_profile_image_mobile {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.header_profile_header_name_mobile {
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.header_profile_header_postname_mobile {
    margin-bottom: 0px;
    color: var(--theme-table-color);
    text-transform: capitalize;
}

.header_profile_list_mobile {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid var(--doctor-booking-border);
}

.header_profile_list_mobile:hover {
    margin-left: 15px;
    color: var(--table-btn-green);
    transition: all 0.8s ease 0s;
}

/* login_signup_button */

.header_login_signup_btn_mobile {
    text-transform: uppercase;
    width: max-content;
    color: var(--theme-main-color);
    font-weight: 600;
    border: 2px solid var(--theme-main-color)
}

.header_login_signup_btn_mobile:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.ant-drawer-body {
    padding: 10px;
}

.ant-drawer-title {
    font-weight: 700;
}


/* bar icon start */

.bar_icon {
    display: inline-block;
    width: 30px;
}

.bar_span_one {
    background-color: var(--theme-main-color);
    height: 3px;
    margin-bottom: 6px;
    display: block;
    border-radius: 2px;
    width: 30px;
    float: left;
}

.bar_span_two {
    background-color: var(--theme-main-color);
    height: 3px;
    margin-bottom: 6px;
    display: block;
    border-radius: 2px;
    width: 15px;
    float: left;
}


.bar_span_three {
    background-color: var(--theme-main-color);
    height: 3px;
    margin-bottom: 0px;
    display: block;
    border-radius: 2px;
    width: 30px;
    float: left;
}


.doctor_header_previous {
    color: var(--secondary-color);
}

.doctor_header_previous:hover {
    border: none;
    color: var(--secondary-color);
}

.doctor_header_previous:active {
    border: none;
    color: var(--theme-main-color);
}

.doctor_header_new_older_title {
    color: var(--black-color);
    font-size: var(--theme-title-main);
    font-weight: 550;
}

.doctor_header_notification_card_first_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doctor_header_notification_patient_image {
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.doctor_header_notification_patient_name {
    font-size: var(--theme-description);
    font-weight: 600;
    font-family: "poppins", sans-serif;
}

.doctor_header_notification_booking_time {
    font-size: var(--theme-description);
    font-weight: 400;
    font-family: "poppins", sans-serif;
    color: var(--theme-main-color);
}

.doctor_header_notification_booking_date {
    font-size: var(--theme-description);
    font-weight: 600;
    font-family: "poppins", sans-serif;
}

.doctor_header_notification_btn_main_div {
    display: flex;
    justify-content: center;
    margin-left: 42px;
}


.doctor_header_notification_accept_btn {
    cursor: pointer;
    font-size: var(--theme-title);
    font-family: "poppins", sans-serif;
    font-weight: 500;
    background-color: var(--table-btn-backgrnd-green);
    color: var(--table-btn-green) !important;
    margin: 0px 3px;
    height: 25px;
    /* width: 45px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.doctor_header_notification_cancel_btn {
    cursor: pointer;
    font-size: var(--theme-title);
    font-family: "poppins", sans-serif;
    font-weight: 500;
    background-color: var(--theme-main-color);
    color: var(--theme-main-color) !important;
    margin: 0px 3px;
    height: 25px;
    /* width: 45px; */
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.doctor_header_notification_time {
    font-size: var(--theme-title);
    display: flex;
    margin-left: 58px;
    margin-top: 5px;
}

.doctor_header_new_older_title {
    color: var(--black-color);
    font-size: var(--theme-title-main);
    font-weight: 550;
}

/* bar icon end   */


/* hrader_mobile_view end */




#full-scr {
    height: 100vh;
    width: 100%;
    background-color: var(--secondary-color);
    position: fixed;
    z-index: 99;
    left: -100%;
    transition: all ease 0.5s;
    padding: 10px;
}

.sidebar_div_two>div {
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500;
}

.sidebar_div_two>div:hover {
    color: #057584;
}

.btn_bar {
    display: none;
}




@media only screen and (max-width:576px) {

    .btn_bar {
        display: block;
    }
   .header_logo_main_division{
    width: 50%;
   }
}

.live_clock {
    font-size: 20px;
    margin-left: 10px;

}

@media only screen and (max-width:768px) {

    .clock_icon {
        display: none;
    }

    .live_clock {
        display: none;
    }
}


@media only screen and (max-width:576px) {
    .header_profile_main_divisoin {
        display: none !important;
    }
}

.header_user_hey {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    color: var(--black-color);
    padding: 12px 22px;
    cursor: pointer;
    width: max-content;

}

.heyy {
    width: max-content;
}

.clock_icon {
    font-size: 20px;
}




/* Add on CSS */


.Adminheader_main_div {
    background-color: var(--ecom-admin-font-color);
    display: flex;
    align-items: center;
    /* margin-top: 80px; */
    justify-content: end;
    width: 100%;
    height: 60px;
}

.adminheader_logo_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.adminheader_logo {
    height: 100%;
}

.adminlheader_logomain_div {
    width: 56%;
    display: flex;
    justify-content: start;
}

.adminheader_bell_main_div {
    display: flex;
    justify-content: end;
    /* width: 43%; */
}

.doctor_header_notification_title {
    font-size: 25px !important;
    color: var(--secondary-color) !important;
}

.notification_button {
    display: flex;
    justify-content: space-between;
}

.admin_notification_item_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}

.notification_main2 {
    display: flex;
    position: fixed;
    z-index: 99;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top: 0px !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    width: 320px;
    padding: 0px 10px;
}

.notification_sidebar {
    height: 100vh;
    width: 320px;
    background-color: var(--black-color);
    position: fixed;
    right: -100%;
    transition: all ease 0.5s;
    cursor: pointer;
    z-index: 100;
    top: 0;
}

.notificitaion_title_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    border-bottom: 1px solid rgb(148, 148, 148);
    padding: 2px;
}

.text_notification {
    color: var(--ecom-admin-second-secondary-color);
}

.icon_div {
    background-color: #d9d9d9;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_div i {
    padding-right: 0px;
}


.notification_container {
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.notification_icon {
    cursor: pointer;
    display: inline-block;
}

.bell_icon {
    color: grey;

}

.notifications_dropdown {
    display: block;
    width: 320px;
    height: 500px;
    overflow-y: scroll;
     background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 40px;
    /* Position below the bell icon */
    right: 0;
    z-index: 1000;
}

.notification_heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.notification {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.notification:last-child {
    border-bottom: none;
}

.profile_pic {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.notification_info {
    flex-grow: 1;
}

.notification_info p {
    margin: 4px 0;
}

.notification_info .doctor {
    color: #007bff;
    cursor: pointer;
}

.time {
    font-size: 12px;
    color: #999;
}