.sidebar_img_profile_main_division {
  display: flex;
  justify-content: center;
}

.sidebar_img_profile_division {
  width: 120px;
  height: 120px;
}

.sidebar_img_profile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sidebar_profile_person_name {
  font-size: var(--title-font);
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.sidebar_birthdate_division {
  font-size: var(--theme-description);
  display: flex;
  justify-content: center;
  color: var(--theme-table-color);
}

.sidebar_birthdate_icon_cake_location {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.sidebar_location_division {
  font-size: var(--theme-description);
  display: flex;
  justify-content: center;
  color: var(--theme-table-color);
}

.sidebar_dashboard_title_division {
  padding: 15px;
  border-top: 1px solid var(--doctor-booking-border);
  color: var(--black-color);
  font-size: var(--theme-title-main);
  cursor: pointer;
  padding: 15px;
  letter-spacing: 0.02em;
}

.sidebar_dashboard_title_division_active {
  color: var(--theme-main-color);
  border-top: 1px solid var(--doctor-booking-border);
  font-size: var(--theme-title-main);
  cursor: pointer;
  padding: 15px;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.sidebar_dashboard_title_division:hover {
  color: var(--theme-main-color);
}

.sidebar_dashboard_title_division:active {
  color: var(--theme-main-color);
}