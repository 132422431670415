@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}

body {
    margin: 0;
    padding: 0;
}

/* @font-face {
    font-family: myFirstFont;
    src: url(/BOOKOS.TTF);
} */



.main_table {
    border-top: 1px solid var(--var(--black-color)-color);
    border-collapse: collapse;
    border-bottom: 1px solid var(--black-color);
}

.main_table td {
    border-right: 1px solid var(--black-color);
}

/* 
.main_table >  th,
td {
    border-bottom: 1px solid var(--black-color);
    border-right: 1px solid var(--black-color);
} */