/* breadcrumb_css_start */
.admin_change_password_breadcrumb_main_division {
   background-color: var(--black-color);
   padding: 10px 30px;
}

.admin_change_password_breadcrumb_division {
   display: flex;
   align-items: center;
   margin-bottom: 5px;
}

.admin_change_password_breadcrumb_title {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   text-transform: capitalize;
   margin-bottom: 0px;
}

.admin_change_password_breadcrumb_title1 {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   margin: 0px 5px;
   text-transform: capitalize;
}

.admin_change_password_breadcrumb_title2 {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   text-transform: capitalize;
   margin-bottom: 0px;
}

.admin_change_password_breadcrumb_title_main {
   color: var(--secondary-color);
   font-size: var(--theme-title-font);
   font-weight: 700;
   text-transform: capitalize;
}


/* breadcrumb_css_end */

.admin_change_passwords_main_diviison {
   border: 1px solid var(--doctor-booking-border);
   border-radius: 5px;
   padding: 25px;
}

.patient_change_password_old_password_eye {
   margin-right: 10px !important;
}


/* admin_change_pass_section_css_start */

.admin_change_password_section_old_password_title {
   font-size: var(--theme-font);
   margin-bottom: 0.5rem;
}

.admin_change_password_section_new_password_title {
   font-size: var(--theme-font);
   margin-bottom: 0.5rem;
}

.admin_change_password_section_confirm_password_title {
   font-size: var(--theme-font);
   margin-bottom: 0.5rem;
}

.admin_change_password_section_input_tag:focus {
   box-shadow: none;
}

.admin_change_password_section_input_tag:focus-within {
   border: 1px solid var(--theme-main-color);
}

.admin_change_password_section_save_button {
   color: var(--secondary-color);
   background-color: var(--theme-main-color);
   border: none;
   padding: 12px 30px;
   font-size: var(--theme-title-main);
   font-weight: 700;
}

.admin_change_password_section_save_button:focus {
   box-shadow: none;
}

.admin_change_password_section_save_button:hover {
   color: var(--secondary-color);
   background-color: var(--theme-main-color);
}

.admin_change_password_section_save_button:active {
   color: var(--secondary-color);
   background-color: var(--theme-main-color);
}

.admin_change_password_section_old_password_section {
   margin-bottom: 1.25rem;
}

.admin_change_password_section_new_password_section {
   margin-bottom: 1.25rem;
}

.admin_change_password_section_confirm_password_section {
   margin-bottom: 1.25rem;
}


/* admin_change_pass_section_css_end */