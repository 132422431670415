.client_register_second_third_merge_main_division {
  width: 100%;
  display: flex;
}

.client_register_second_main_division {
  width: 35%;
  margin-left: 30px;
  margin-right: 30px;
}

.client_register_registration_main_division {
  display: flex;
  border-radius: 3px;
  border: 1px solid var(--doctor-booking-border);
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.client_register_registration_main_division:hover {
  box-shadow: 8px 7px 14px -15px var(--patient-register-color);
}


.client_register_registration_number_title_active {
  background-color: var(--black-color);
  color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}

.client_register_registration_division {
  font-weight: 700;
}





.client_register_registration_number_division {
  /* width: 8%; */
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client_register_registration_number_title {
  background-color: var(--doctor-booking-border);
  color: var(--theme-color-black);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* registration css start*/

.client_register_third_main_division {
  width: 65%;
  border: 1px solid var(--doctor-booking-border);
  border-radius: 3px;
  padding: 50px;
}

.client_register_registration_details_main_division {
  font-size: var(--title-large-font);
  font-weight: 600;
  margin-bottom: 5px;
}

.client_register_registration_star_icon {
  color: var(--client-upload-image-bs);
  margin-left: 5px;
}

.client_register_registration_details_sub_division {
  color: var(--black-color);
}


.client_register_email_field {
  height: 60px;
}

.client_register_email_field:focus {
  box-shadow: none;
  border-color: var(--doctor-booking-second-border);
}




.client_register_date_feild_division {
  margin-top: 15px;
}


.client_register_gluecose_div {
  display: flex;
}

.client_register_continue_btn {
  border: none;
  margin-top: 15px;
  background-color: var(--black-color);
  color: var(--secondary-color);
  font-weight: 600;
  font-size: var(--title-small-font);
  border-radius: 5px;
  height: 55px;
  transition: all 0.5s;
}

.client_register_continue_btn:hover {
  box-shadow: inset 0 60px 0 0 var(--theme-main-color);
}

/* registration css end*/

/* profile-picture start */

.client_register_upload_img_main_division {
  display: flex;
  justify-content: center;
  height: 230px;
  margin-top: 20px;
}

.client_register_upload_img_division {
  background-color: var(--doctor-booking-second-border);
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--main-title-large);
  color: var(--theme-table-color);
  cursor: pointer;
}

.client_register_upload_images_main_division {
  display: flex;
  justify-content: center;
}


/* new upload button css start */

.clientregister_upload_photo_button {
  border: 1px solid var(--doctor-booking-border);
  box-shadow: 0px 4px 10px var(--client-upload-image-bs);
  margin-top: 30px;
  width: 100%;
  padding: 15px 0px;
  color: var(--theme-color-black) !important;
  background-color: var(--secondary-color) !important;
  font-weight: 600;
  font-size: var(--theme-font);
  position: relative;
  line-height: 1.5;
  color: var(--secondary-color);
  overflow: hidden;
  font-family: "poppins", sans-serif;
}

.clientregister_upload_photo_button input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(3);
  opacity: 0;
}

.clientregister_upload_photo_button:focus {
  border: 1px solid var(--doctor-booking-border);
  box-shadow: none;
}

.clientregister_upload_photo_button:hover {
  border: 1px solid var(--doctor-booking-border);
  color: var(--theme-color-black);
  position: relative;
  font-size: var(--theme-font);
  line-height: 1.5;
  background-color: var(--secondary-color);
  border: 0;
  overflow: hidden;

}


/* new upload button css end */




/* personal-details css start */

.client_register_personal_gender_main_division {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: 60px;
}


.client_register_personal_female_btn,
.client_register_personal_male_btn {
  width: 45%;
  font-size: var(--theme-title-main);
  box-shadow: 0px 4px 24px var(--client-upload-image-bs);
  border: none;
  font-weight: 700;
  background-color: var(--secondary-color);
  border: 1px solid var(--black-color);

}

.client_register_personal_female_btn_active,
.client_register_personal_male_btn_active {
  width: 45%;
  font-size: var(--theme-title-main);
  color: var(--secondary-color);
  box-shadow: 0px 4px 24px var(--client-upload-image-bs);
  border: none;
  font-weight: 700;
  background-color: var(--theme-main-color);
}

.client_register_email_field_division_1 {
  margin-left: 10px;
  width: 50%;
}

.client_register_blood_type_deopdown_division_1 {
  margin-left: 10px;
  width: 50%;
}

/* personal-details css end */


/* client register responsive css start */


@media only screen and (max-width:768px) {


  .client_register_third_main_division {
    padding: 20px;
  }

  .client_register_first_main_division {
    display: none;
  }


  .client_register_weight_dropdown_division {
    display: block;
  }


  .client_register_gluecose_div {
    display: block;
  }


  .client_register_email_field_division_1 {
    width: 100%;
    margin-left: 0px;
  }

}




@media only screen and (max-width:576px) {

  .client_register_second_third_merge_main_division {
    display: block;
  }

  .client_register_second_main_division {
    width: 100%;
    margin: 0px;
  }

  .client_register_third_main_division {
    width: 100%;
  }

}




/* client register responsive css end*/