.Profilesettings_breadcrumb_main_division {
   background-color: var(--black-color);
   padding: 10px 30px;
}

.Profilesettings_breadcrumb_division {
   display: flex;
   align-items: center;
   margin-bottom: 5px;
}

.Profilesettings_breadcrumb_title {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   text-transform: capitalize;
   margin-bottom: 0px;
   font-family: "poppins", sans-serif;
}

.Profilesettings_breadcrumb_title1 {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   margin: 0px 5px;
   text-transform: capitalize;
   font-family: "poppins", sans-serif;
}

.Profilesettings_breadcrumb_title2 {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   text-transform: capitalize;
   margin-bottom: 0px;
   font-family: "poppins", sans-serif;
}

.Profilesettings_breadcrumb_title_main {
   color: var(--secondary-color);
   font-size: var(--theme-title-font);
   font-weight: 700;
   text-transform: capitalize;
   font-family: "poppins", sans-serif;
}

/* breadcrumb_css_end */

.Profilesettings_main_diviison {
   border: 1px solid var(--doctor-booking-border);
   border-radius: 5px;
   padding: 25px;
}


.profilesettings_Upload_image {
   display: flex;
   align-items: center;
}

.profilesettings_profile_image_sizes {
   height: 100px;
   width: 100px;
   border-radius: 20px;
}

.profilesettings_upload_button_main {
   padding-left: 2%;
}

.profilesettings_upload_photo_button {
   background-color: var(--theme-main-color);
   padding: 8px 20px;
   color: var(--secondary-color);
   font-weight: 600;
   font-size: var(--theme-font);
   position: relative;
   line-height: 1.5;
   border-radius: 3px;
   color: var(--secondary-color);
   border: 0;
   overflow: hidden;
   border-radius: 30px;
   font-family: "poppins", sans-serif;
   white-space: nowrap;
}

.profilesettings_upload_photo_button input[type="file"] {
   cursor: pointer;
   position: absolute;
   left: 0;
   top: 0;
   transform: scale(3);
   opacity: 0;
}

.profilesettings_upload_photo_button:focus {
   box-shadow: none;
}

.profilesettings_upload_photo_button:hover {
   color: var(--secondary-color);
   position: relative;
   font-size: var(--theme-font);
   line-height: 1.5;
   color: var(--secondary-color);
   background-color: var(--theme-main-color);
   border: 0;
   overflow: hidden;
   border-radius: 30px;
}



.profilesetting_upload_p {
   padding-top: 2%;
   color: var(--theme-table-color);
   font-size: small;
   font-family: "poppins", sans-serif;
}

.profilesetting_input_form {
   display: flex;
   padding-top: 2%;
}

.profilesettings_label {
   font-weight: 500;
}

.profilesettings_input {
   min-height: 46px;
}

.profilesettings_input:focus {
   box-shadow: none;
   border: 1px solid var(--theme-main-color) !important;
}

.profilesettings_Date_of_birth {
   width: 50%;
}

.profilesettings_weight_dropdown_division {
   display: flex;
}

.profilesettings_details {
   display: flex;
}

.profilesettings_weight {
   width: 35%;
   margin-right: 2%;
}

.profilesettings_height {
   width: 35%;
   margin-right: 2%;
}

.profilesettings_Age {
   width: 50%;
}


.profilesettings_address_textarea:focus {
   box-shadow: none;
   border: 1px solid var(--theme-main-color);
}

.profilesettings_savechanges_button {
   background-color: var(--theme-main-color);
   border-color: var(--theme-main-color);
   padding: 15px 25px;
   width: max-content;
   box-shadow: none;
   color: var(--secondary-color);
   font-weight: 700;
   white-space: nowrap;
}

.profilesettings_savechanges_button:focus {
   box-shadow: none;
}

.profilesettings_savechanges_button:hover {
   box-shadow: none;
   background-color: var(--theme-main-color);
   border-color: var(--theme-main-color);
   color: var(--secondary-color);
}

.tab_class {
   border-radius: 5px;
   margin-right: 10px;
   font-size: 20px;
   padding: 5px 10px;
}

.tab_class_active {
   border-radius: 5px;
   margin-right: 10px;
   font-size: 20px;
   border-radius: 20px;
   background-color: var(--black-color);
   color: var(--secondary-color);
   padding: 5px 10px;
}


/* client profile setting responsive css :start */




@media only screen and (max-width:430px) {

   .component_division {
      width: 100% !important;
   }
}

/* client profile setting responsive css end */



.medical-form {
   margin: 0 auto;
   padding: 20px;
   border-radius: 8px;
}

.form-section {
   margin-bottom: 20px;
}

.form-heading {
   font-size: 18px;
   margin-bottom: 10px;
   color: #333;
}

.form-group {
   margin-bottom: 15px;
}

.form-label {
   display: block;
   font-size: 14px;
   color: #555;
}

.form-input,
.form-textarea,
.form-date {
   width: 100%;
   padding: 8px;
   margin-top: 5px;
   border: 1px solid #ccc;
   border-radius: 4px;
   font-size: 14px;
}

.form-textarea {
   resize: vertical;
}

.form-radio-group {
   display: flex;
   gap: 10px;
   margin-top: 5px;
}

.form-radio {
   display: none;
   /* Hide the original radio button */
}

.form-radio-label {
   position: relative;
   padding-left: 25px;
   cursor: pointer;
   font-size: 14px;
   color: #555;
}

.form-radio-label::before {
   content: "";
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   width: 18px;
   height: 18px;
   border: 2px solid #ccc;
   border-radius: 50%;
   background-color: white;
}

.form-radio:checked+.form-radio-label::before {
   background-color: var(--theme-main-color);
   /* Active color for checked radio */
   border-color: var(--theme-main-color);
   /* Border color for checked radio */
}

.form-radio:checked+.form-radio-label::after {
   content: "";
   position: absolute;
   left: 4px;
   top: 50%;
   transform: translateY(-50%);
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background-color: white;
}