.footer_main_div {
    position: relative;
}

.ff_first_main {
    background: linear-gradient(180deg, var(--secondary-color) 50%, var(--doctor-booking-border) 50%);
}

.nfooter_first_div {
    background-color: var(--black-color);
    border-radius: 20px;
    color: var(--secondary-color);
    margin: 0px 10px;
    padding: 50px;
}


.input_div {
    height: 40px !important;
    border-radius: 0px;
    padding: 1.2em;
    border: none;
}

.input_div:focus {
    box-shadow: none;
}


.input_tag_div {
    display: flex;
}

.sub_btn {
    height: 40px;
    border: 1px solid var(--theme-main-color);
    border-radius: 0px;
    background-color: var(--secondary-color);
    margin-left: 10px;
    padding: 0px 20px;
}

.steller_div {
    font-size: 24px;
}

.sub_btn:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    transition: 0.5s;
}


.sub_btn:focus {
    box-shadow: none;
}


.nfooter_second_div {
    background-color: var(--doctor-booking-border);
}


.nfooter_ul_list li {
    list-style-type: none;
    margin-top: 10px;
    cursor: pointer;
    color: var(--theme-main-color);
    display: flex;
    align-items: center;
    font-size: 16px;
}


@media only screen and (max-width:768px) {

    .nfooter_ul_list li {
        font-size: 14px;
    }

    .sub_div_main {
        display: block !important;
    }

    .nfooter_first_div {
        padding: 10px;
    }

    .sub_btn{
        margin-top: 5px;
    }
}

.sub_div_main {
    display: flex;
}

.tell {
    color: var(--theme-main-color);
}

.nfooter_ul_list li i {
    color: var(--black-color) !important;
}

.nfooter_ul_list :hover {
    color: var(--black-color);
}

.nfooter_logo {
    width: 80%;
}

.google_play {
    width: 90%;
    margin-right: 10px;
}

.app_store {
    width: 90%;
}

.application_link {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}


.nfooter_third_div {
    background-color: var(--black-color);
    text-align: center;
    font-size: 22px;
    color: var(--secondary-color);
}

@media only screen and (max-width:425px) {
    .nfooter_third_div {
        font-size: 16.5px;
    }
}