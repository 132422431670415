.schedule_timing_breadcrumb_main_division {
    background-color: var(--black-color);
    padding: 10px 30px;
}

.schedule_timing_breadcrumb_division {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.schedule_timing_breadcrumb_title {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    text-transform: capitalize;
    margin-bottom: 0px;
}

.schedule_timing_breadcrumb_title1 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    margin: 0px 5px;
    text-transform: capitalize;
}

.schedule_timing_breadcrumb_title2 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    text-transform: capitalize;
    margin-bottom: 0px;
}

.schedule_timing_breadcrumb_title_main {
    color: var(--secondary-color);
    font-size: var(--theme-title-font);
    font-weight: 700;
    text-transform: capitalize;
}


.schedule_timings_main_diviison {
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
    padding: 25px;
}

.schedule_timings_diviison {
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
}

.schedule_timings_week_division {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    border-bottom: 1px solid var(--doctor-booking-border);
}

.schedule_timings_week_name_active {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    width: 110px;
    padding: 5px 0px;
    border-radius: 5px;
    cursor: pointer;
}

.schedule_timings_week_name {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid var(--doctor-booking-second-border);
    width: 110px;
    padding: 5px 0px;
    border-radius: 5px;
    cursor: pointer;
}

.schedule_timings_week_name:hover {
    background-color: var(--doctor-booking-border);
}

.schedule_timings_time_slot_division {
    padding: 20px;
}

.available_1 {
    color: var(--theme-main-color);
}

.available_2 {
    color: var(--slot-border-color);
}

.schedule_timings_time_slote_title {
    font-size: var(--title-font);
    font-weight: 600;
    text-transform: capitalize;
}

.schedule_timings_time_slot_edit_icon {
    color: var(--theme-main-color);
    font-size: var(--theme-title-main);
    font-weight: 600;
}

.schedule_timings_edit_popup_main_division {
    padding-bottom: 30px;
    height: max-content;
}

.schedule_timings_edit_popup_division {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--doctor-booking-border);
    padding: 10px 30px;
}

.schedule_timings_edit_popup_EditTimeSlot_name {
    font-size: var(--theme-title-font);
    font-weight: 600;
}

.schedule_timings_edit_popup_content {
    padding: 5px 30px;
}

.schedule_timings_edit_popup_timingslot_select_division {
    margin-top: 15px;
}

.schedule_timings_edit_popup_timingslot_select {
    width: 30%;
    padding: 10px;
}

.schedule_timings_edit_popup_timingslot_select:focus-visible {
    box-shadow: none !important;
    width: 30%;
    padding: 10px;
}

.schedule_timings_edit_popup_priceslote_name {
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 500;
}

.schedule_timings_edit_popup_price_input {
    padding: 10px;
    width: 30%;
}

.schedule_timings_edit_popup_price_input:focus-visible {
    border: none !important;
}

.schedule_timings_clear_current_dayslot_button_division {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.schedule_timings_clear_current_dayslot_button {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    font-weight: 600;
    text-align: center;
}

.schedule_timings_clear_current_dayslot_button:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.schedule_timings_time_slot_table_division {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 10px;
    padding: 0px 20px 20px 20px;
}

.schedule_timings_time_slots_active {
    text-align: center;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    padding: 5px 0px;
    border-radius: 3px;
    border: 1px solid var(--slot-border-color);
    cursor: pointer;
}

.schedule_timings_time_slots {
    text-align: center;
    background-color: var(--slot-border-color);
    padding: 5px 0px;
    border-radius: 3px;
    border: 1px solid var(--slot-border-color);
    cursor: pointer;
}


.schedule_timings_save_slot_button_division {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.schedule_timings_save_slot_button {
    width: 20%;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    font-weight: 600;
    text-align: center;
}

.schedule_timings_save_slot_button:hover {

    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}













@media only screen and (max-width:1024px) {
    .schedule_timings_week_division {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .schedule_timings_week_name {
        margin-top: 5px;
    }

    .schedule_timings_week_name_active {
        margin-top: 5px;
    }

    .schedule_timings_time_slot_table_division {
        display: grid;
        grid-template-columns: auto auto auto;
    }


}


@media only screen and (min-width:1025px) and (max-width:1439px) {

    .schedule_timings_week_division {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .schedule_timings_week_name {
        margin-top: 5px;
    }

    .schedule_timings_week_name_active {
        margin-top: 5px;
    }

    .schedule_timings_time_slot_table_division {

        display: grid;
        grid-template-columns: auto auto auto auto;
    }

}

@media only screen and (max-width:768px) {
    .schedule_timings_week_division {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .schedule_timings_main_diviison {
        margin-top: 10px;
    }
}


@media only screen and (min-width:769px) and (max-width:1023px) {

    .schedule_timings_week_division {
        display: grid !important;
        grid-template-columns: auto auto auto !important;
    }

    .schedule_timings_time_slot_table_division {
        display: grid !important;
        grid-template-columns: auto auto auto !important;
    }
}

@media only screen and (max-width:430px) {
    .schedule_timings_week_division {
        display: grid;
        grid-template-columns: auto auto;
    }

    .schedule_timings_time_slot_table_division {
        display: grid;
        grid-template-columns: auto auto;
    }

    .schedule_timings_save_slot_button {
        width: 110px;
    }

    .schedule_timings_title {
        font-size: var(--theme-font);
    }
}


@media only screen and (min-width:431px) and (max-width:767px) {

    .component_division {
        width: 100%;
    }

    .schedule_timings_week_division {
        display: grid;
        grid-template-columns: auto auto;
    }

    .schedule_timings_time_slot_table_division {
        display: grid;
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width:375px) {

    .schedule_timings_time_slot_table_division {
        display: grid;
        grid-template-columns: auto;
    }
}


@media only screen and (min-width:320px) and (max-width:350px) {
    .schedule_timings_week_division {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto;
        column-gap: 5px;
    }


}