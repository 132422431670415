.doccure_login_main_division {
    display: flex;
    width: 100%;
    padding: 40px 150px;
}

.doccure_login_main_first_division {
    width: 50%;
}


.doccure_login_img {
    width: 100%;
}

.doccure_login_main_second_division {
    width: 50%;
    border: 1px solid var(--doctor-booking-border);
    padding: 30px;
    border-radius: 3px;
}

.doccure_login_title_name {
    font-size: var(--title-font);
    font-weight: 600;
}

.doccure_login_email_field1 {
    width: 100%;
    border-radius: 12px;
}

.doccure_login_email_field {
    width: 100%;
    border: none !important;
}

.doccure_login_email_field:focus {
    box-shadow: none;
}

.doccure_login_email_field {
    border-radius: 12px;
}

.doccure_login_email_field_division1 {
    width: 100%;
    color: var(--theme-table-color);
}

.doccure_login_email_field_division>label {
    color: var(--theme-table-color);
    font-size: var(--theme-font);
    display: flex;
}

.doccure_login_password_field_division {
    display: flex;
    width: 100%;
    border: 1px solid var(--border-color);
    margin-top: 20px;
    border-radius: 12px;
}

.doccure_login_password_eyes_icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.doccure_password_eye {
    display: flex;
}

.doccure_login_password_eye {
    align-items: center;
    display: flex;
}

.eye_icon {
    top: 20%;
    right: 1%;
    cursor: pointer;
    color: var(--theme-table-color);
}

.doccure_login_forgot_password_division {
    display: flex;
    justify-content: end;
    font-size: var(--theme-description);
    color: var(--theme-main-color);
    cursor: pointer;
}


.doccure_login_btn_login_division {
    width: 100%;
    height: 50px;
    font-size: var(--title-font);
    border: none;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    border-radius: 6px;
}


.doccure_login_new_account_title_division {
    text-align: end;
    color: var(--theme-main-color);
    font-size: var(--theme-font);
    cursor: pointer;
}


.doccure_login_btn_login_division:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

@media only screen and (max-width:1024px) {

    .doccure_login_main_division {
        padding: 0px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width:576px) {

    .doccure_login_main_division {
        display: block;
    }

    .doccure_login_main_second_division,
    .doccure_login_main_first_division {
        width: 100%;
    }

}

.form-check-input:checked {
    background-color: var(--theme-main-color);
    border-color: var(--theme-main-color);
}

.form-check-input:focus {
    box-shadow: none;
}