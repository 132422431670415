/* breadcrumb_css_start */
.client_dashboard_breadcrumb_main_division {
  background-color: var(--black-color);
  padding: 10px 30px;
}

.client_dashboard_breadcrumb_division {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.client_dashboard_breadcrumb_title {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  text-transform: capitalize;
  margin-bottom: 0px;
}

.client_dashboard_breadcrumb_title1 {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  margin: 0px 5px;
  text-transform: capitalize;
}

.client_dashboard_breadcrumb_title2 {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  text-transform: capitalize;
  margin-bottom: 0px;
}

.client_dashboard_breadcrumb_title_main {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}


/* breadcrumb_css_end */

/* client-dashboard css start */


.client_dashboard_main_division {
  border: 1px solid var(--doctor-booking-border);
  border-radius: 5px;
  padding: 25px;
}

.client_bashboard_tab_division {
  display: flex;
  border-bottom: 1px solid var(--doctor-booking-second-border);
}

@media only screen and (max-width:576px) {
  .client_bashboard_tab_division {
    display: block;
  }
}

.client_bashboard_tabs_title_name_division_active {
  color: var(--theme-main-color);
  border-bottom: 3px solid var(--theme-main-color);
}

.client_bashboard_tabs_title_name {
  font-size: var(--theme-title-main);
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.client_bashboard_tabs_title_name:hover {
  color: var(--theme-main-color);
}

.client_bashboard_tabs_title_name_division {
  cursor: pointer;
}

.client_bashboard_tabs_title_name_division:hover {
  border-bottom: 3px solid var(--doctor-booking-second-border);
}

.client_bashboard_tabs_title_name_division:active {
  border-bottom: 3px solid var(--theme-main-color);
}


.addmedical_pop_text_feild:focus {
  border: 1px solid var(--theme-main-color) !important;
  box-shadow: none !important;
}

.client_dashboard_table_confirm_btn {
  border: none;
  border-radius: 3px;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  font-weight: 500;
  margin-left: 3px;
}

.client_dashboard_table_reject_btn {
  border: none;
  border-radius: 3px;
  font-weight: 500;
  margin-left: 3px;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
}

.client_dashboard_table_pending_btn {
  border: none;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  border-radius: 3px;
  white-space: nowrap;
}

.client_dashboard_table_print_btn {
  background-color: var(--table-btn-backgrnd-blue);
  border: none;
  color: var(--black-color);
  border-radius: 3px;
}

.client_dashboard_table_view_btn {
  background-color: var(--table-btn-backgrnd-blue);
  border: none;
  color: var(--black-color);
  border-radius: 3px;
}

.client_dashboard_table_appdate_date {
  font-weight: 400;
  font-size: var(--theme-font);
  white-space: nowrap;
}

.client_dashboard_table_appdate_time {
  color: var(--theme-main-color);
  font-weight: 500;
  white-space: nowrap;
  background-color: #E2E8F0;
}

.client_dashboard_table_doctor_name {
  font-size: var(--theme-font);
}

/* client-dashboard pagination css start */

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-item-active a {
  color: var(--theme-main-color) !important;
  padding: none !important;
}

.ant-pagination-item {
  border: none !important;
}

/* client-dashboard pagination css end */



.client_dashboard_table_division {
  margin-top: 20px;
}


/* client-dashboard responsive start */

@media only screen and (max-width:1024px) {
  .popup-content {
    margin: 138px 151px !important;
  }
}

@media only screen and (max-width:768px) {

  .component_division {
    width: 100% !important;
    padding-left: 0px !important;
  }

  .ant-table-tbody>tr>td {
    font-size: var(--theme-description) !important;
  }


  .popup-content {
    margin: 138px 151px !important;
  }

}

@media only screen and (max-width:767px) {
  .Dashboard_table {
    width: 100%;
  }


}


@media only screen and (min-width:768px) and (max-width:1024px) {

  .client_dashboard_table_division {
    margin-top: 20px;
    overflow-x: scroll;
  }
}


@media only screen and (min-width:1025px) and (max-width:1129px) {

  .client_dashboard_table_division {
    overflow-x: scroll;
  }
}

@media only screen and (max-width:430px) {

  .popup-content {
    margin: 70px 10px 110px 10px !important;
  }
}

.required_feild {
  color: var(--theme-main-color);
  font-size: small;
  text-align: center;
}

/* client-dashboard responsive end */