.not-found {
    text-align: center;
    margin-top: 50px;
  }
  
  .title{
    font-size: 4rem;
  }
  
  .err_msg{
    font-size: 1.5rem;
  }

  .home_page{
    font-size: 1.5rem;
    cursor: pointer;
    text-decoration: underline;
  }