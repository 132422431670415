.sidebar_main_division {
  width: 20%;
  border: 1px solid var(--doctor-booking-border);
  border-radius: 10px;
}

.sidebar_division {
  border-radius: 5px;
  padding: 20px;
}

.sidebar_img_profile_main_division {
  display: flex;
  justify-content: center;
}

.sidebar_img_profile_division {
  width: 120px;
  height: 120px;
}

.sidebar_img_profile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.sidebar_profile_person_name {
  font-size: var(--title-font);
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.sidebar_birthdate_division {
  font-size: var(--theme-description);
  display: flex;
  justify-content: center;
  color: var(--theme-table-color);
}

.sidebar_birthdate_icon_cake_location {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.sidebar_location_division {
  font-size: var(--theme-description);
  display: flex;
  justify-content: center;
  color: var(--theme-table-color);
}

.sidebar_dashboard_title_division {
  padding: 15px;
  color: var(--theme-table-color);
  font-size: var(--theme-title-main);
  cursor: pointer;
  padding: 15px;
}

.sidebar_dashboard_title_division_active {
  color: var(--theme-main-color);
  border-top: 1px solid var(--doctor-booking-border);
  font-size: var(--theme-title-main);
  cursor: pointer;
  padding: 15px;
}

.sidebar_dashboard_title_division:hover {
  color: var(--theme-main-color);
}

.sidebar_dashboard_title_division:active {
  color: var(--theme-main-color);
}

.User_detail_main_div {
  position: relative;
  text-align: center;
}

.profile_info {
  display: flex;
  justify-content: center;
}

.profile_info_img_div {
  border-radius: 50%;
  background-color: #f7f7f7;
  height: 120px;
  width: 120px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


.profile_info_img_div img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
}