/* breadcrumb_css_start */
.add_prescription_main_division {
    background-color: var(--theme-main-color);
    padding: 10px;
}

.add_prescription_division {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.add_prescription_title {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    text-transform: capitalize;
    margin-bottom: 0px;
}

.add_prescription_title1 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    margin: 0px 5px;
    text-transform: capitalize;
}

.add_prescription_title2 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    text-transform: capitalize;
    margin-bottom: 0px;
}

.add_prescription_title_main {
    color: var(--secondary-color);
    font-size: var(--theme-title-font);
    font-weight: 700;
    text-transform: capitalize;
}

.slidebar_and_component {
    display: flex;
    padding: 30px 30px;
}

/* breadcrumb_css_end */


.component_division {
    width: 80%;
    padding-left: 15px;
}

.add_prescription_diviison {
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
}

.add_prescription_main_diviison {
    border-radius: 5px;
}

.add_prescription_edit_Name {
    padding: 20px;
    font-size: var(--theme-title-font);
    border-bottom: 1px solid var(--doctor-booking-border);
    font-weight: bold;
    font-family: "poppins", sans-serif;
}

.add_prescription_main_coach_info {
    width: 100%;
    display: flex;
}

.add_prescription_second_main_division {
    padding: 20px;
}

.add_prescription_coach_info {
    width: 50%;
}

.add_prescription_coach_name {
    font-weight: bold;
    font-size: var(--theme-title-font);
    font-family: "poppins", sans-serif;
}

.add_prescription_coach_job {
    color: var(--theme-table-color);
    text-transform: capitalize;
    font-family: "poppins", sans-serif;
    font-size: var(--theme-description);
}

.add_prescription_coach_city {
    /* padding-top:1px; */
    font-family: "poppins", sans-serif;
    color: var(--theme-table-color);
    text-transform: capitalize;
    font-size: var(--theme-description);
}

.add_prescription_date_division {
    width: 50%;
    justify-content: end;
    display: flex;
    font-family: "poppins", sans-serif;
    font-weight: bold;
    font-size: var(--theme-title-font);
}

.add_prescription_id_code {
    justify-content: end;
    display: flex;
    font-family: "poppins", sans-serif;
    color: var(--theme-table-color);
    font-size: var(--theme-description);
    font-weight: normal;
}

.add_prescription_Add_more {
    justify-content: end;
    display: flex;
    align-items: center;
    color: var(--theme-main-color);
    padding-top: 30px;
    font-weight: bold;
    font-family: "poppins", sans-serif;
    font-size: var(--theme-font);
}

.add_prescription_plue_icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border: none !important;
}

.ant-pagination-item-active a {
    color: var(--theme-main-color) !important;
    padding: none !important;
}

.ant-pagination-item {
    border: none !important;
}

/* .table-hover tbody tr:hover {
    background-color: #843b3b !important;
} */

.add_prescription_table_image_name_division {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.add_prescription_name_division {
    display: flex;
    width: 180px;
}

.add_prescription_name_division:focus {
    box-shadow: none;
}

.add_prescription_name_division:focus-visible {
    border: 1px solid var(--doctor-booking-second-border);
}

.add_prescription_quantity_division {
    display: flex;
    width: 120px;
}

.add_prescription_quantity_division:focus {
    box-shadow: none;
}

.add_prescription_quantity_division:focus-visible {
    border: 1px solid var(--doctor-booking-second-border);
}

.add_prescription_day_division {
    display: flex;
    width: 120px;
}

.add_prescription_day_division:focus {
    box-shadow: none;

}

.add_prescription_day_division:focus-visible {
    border: 1px solid var(--doctor-booking-second-border);
}

.add_prescription_cheackbox_division {
    font-size: var(--main-title-time);
    display: flex;
    align-items: center;
}

.add_prescription_cheackbox_sub_division {
    display: flex;
    margin-right: 5px;
}

.add_prescription_cheackbox_sub_division1 {
    display: flex;
    justify-content: center;
    margin-right: 5px;
}

.add_prescription_cheacbox_button {
    margin-right: 5px;
}

.add_prescription_delate_icon_division {
    display: flex;
    justify-items: end;
}

.add_prescription_delate_icon {
    background-color: var(--theme-main-color);
    color: var(--theme-main-color);
    width: 35px;
    height: 35px;
    padding: 8px;
    border-radius: 3px;
}

.add_prescription_button_main_division {
    display: flex;
    justify-content: start;

}

.add_prescription_click_here_main_division {
    justify-content: end;
    display: flex;
}

.add_prescription_click_bar {
    border: 2px dashed var(--theme-table-color);
    height: 85px;
    width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: var(--theme-font);
    font-family: "poppins", sans-serif;
    border-radius: 4px;
}

.add_prescription_docter_name_dr_main_division {
    display: flex;
    justify-content: end;
}

.add_prescription_docter_name_dr_division {
    display: flex;
    justify-content: center;
    width: 20%;
    margin-top: 20px;
}

.add_prescription_docter_dr {
    font-weight: bold;
    font-size: var(--theme-font);
}

.add_prescription_signature_division {
    text-transform: capitalize;
    font-weight: bold;
    color: var(--theme-table-color);
    font-size: var(--theme-font);
    display: flex;
    justify-content: center;
}

.add_prescription_save_button {
    cursor: pointer;
    height: 48px;
    width: 110px;
    background-color: var(--theme-color-green);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    color: var(--secondary-color);
    font-size: var(--theme-title-main);
}

.add_prescription_clear_button {
    margin-left: 13px;
    cursor: pointer;
    height: 48px;
    width: 110px;
    background-color: var(--theme-table-color);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    color: var(--secondary-color);
    font-size: var(--theme-title-main);
}

.add_prescription_patient_appointment_table_division {
    margin-top: 10px;
}

.add_prescription_patient_appointment_table_division:focus {
    overflow: hidden;
    border: none;

}

/* table_css_end */