.main_div_about_page {
  background-image: url(../../media/about/abback.png);
  color: var(--secondary-color);
  background-repeat: no-repeat;
  background-size: cover;
}



.about_div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6.25rem;
  font-weight: 400;
  padding-top: 20%;
}

.about_heading_div {
  text-align: center;
  font-size: 1.625rem;
  font-style: italic;
  font-family: poppins;
  font-weight: 400;
  padding-bottom: 20%;
}

.expertise_main_div {
  display: flex;
justify-content: start;
  margin-top: 10%;


}

.future_div {
  font-size: 3.75rem;
  font-weight: 400;
  font-family: poppins;
  padding-top: 20px;
  text-align: justify;
}

.introduction_div {
  color: grey;
  text-align: justify;
}

.about_list {
  color: #313c4c;
  font-size: 1.2rem;
  text-align: justify;
  font-weight: 400;
  font-family: poppins;
}

.card_1_div {
  display: flex;
  align-items: center;
  padding: 0.5em;

}

.content_div {
  font-size: 1rem;
  font-weight: 700;
  transition: transform 300ms ease;
  color: #313c4c;
  padding: 0.5em;
}



.part_1_div {
  border-top: 1px solid #ccc;
}

.service {
  font-size: .85rem;
  font-weight: 500;
  margin-bottom: 1.1rem;
  margin: 10px;
}

.content_part {
  font-size: 20px;
  font-weight: 600;
  color: #313C4C;
  margin-bottom: 1em;
}

@media screen and (max-width: 576px) {
  .about_div {
    font-size: 3rem;

  }
}





@media screen and (max-width: 576px) {
  .future_div {
    font-size: 2.5rem;

  }

  .expertise_main_div {
    display: block;
    align-items: center;

    margin-top: 10%;


  }

  .content_div {
    font-size: 0.9rem;
  }
}




.logo_image {
  height: 80px;
  width: 80px;

}







@media screen and (max-width: 570px) {


  .vision_mission_div_main {
    padding: 10px;
    padding-bottom: 10px;
  }
}


.mission_part {
  font-weight: 400;
  font-size: 1rem;
  padding-top: 20px;
}


.left-mission {
  padding-left: 3rem;

}


.mission_image {
  max-width: 100%;
  height: auto;
}

.mission_photo {
  text-align: -webkit-right;
  padding-right: 10px;
}



.tag_contact_background {
  padding: 70px 0px;
  background-color: #057584;
  margin: 30px 0px;
}

.tag_contact_div {
  text-align: center;
}

.tag_line {
  padding-bottom: 20px;
  color: var(--secondary-color);
}

.contact_btn {
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: var(--black-color);
  font-size: 1.5rem;
  box-shadow: none;
  padding: 10px;
  width: 15%;
  margin-left: 20px;
}


.contact_btn:hover {

  color: var(--secondary-color);
  background-color: var(--black-color);

}

.contact_btn:focus {
  box-shadow: none;
}

.vinny_mission {

  color: var(--black-color);
  font-size: 3rem;
}

.empower_content {
  color: var(--black-color);
  font-size: 25px;
  text-align: justify;

}

.mission_vision_main_div {
  margin-top: 20px;
  justify-content: space-between;

}


@media screen and (max-width: 570px) {




  .vinny_mission {
    font-size: 30px;
  }

  .mission_vision_main_div {
    display: block;
    text-align: center;
  }

}

@media screen and (max-width: 768px) {


  .vinny_mission {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .tag_contact_div {
    display: block;
    text-align: center;
  }

  .contact_btn {
    width: 30%;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 570px) {
  .tag_line {
    font-size: 15px;
    color: var(--secondary-color);
    margin-bottom: 30px;
    padding: 10px 0px;
  }

  .contact_btn {
    width: 40%;
    margin-bottom: 1rem;
    font-size: 15px;
  }
}