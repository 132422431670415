:root {
  --theme-main-color: #743A87;
  --secondary-color: #fff;
  --black-color: #BE5A9F;
  --doctor-booking-border: #f0f0f0;
  --doctor-booking-second-border: #dcddea;

  --theme-table-color: #888888;
  --slot-border-color: #e9e9e9;
  --table-btn-backgrnd-blue: #02b6b31f;
  --table-btn-backgrnd-green: rgba(15, 183, 107, 0.12);
  --table-btn-green: #26af48;
  --doc-patient-profile-bg-yellow: rgb(255 152 8 / 12%);
  --doc-header-bs: rgb(0 0 0 / 10%);
  --client-upload-image-bs: rgb(225 225 225 / 25%);
}

:root {
  --theme-title: 12px;
  --theme-description: 14px;
  --theme-font: 15px;
  --theme-title-main: 16px;
  --title-font: 18px;
  --theme-title-font: 20px;
  --main-title: 24px;
  --main-title-time: 13px;
  --title-small-font: 17px;
  --title-large-font: 22px;
  --main-title-large: 30px;
  --main-primary-font: 48px;
  --secondary-font: 40px;
  --main-secondary-font: 28px;
  --secondary-main: 36px;
  --primary-font: 26px;
  --primary-main-font: 38px;
  --theme-primary-font: 44px;
  --theme-second-font: 21px;
}

body {
  font-family: "poppins", sans-serif !important;
}

.App {
  text-align: center;
}

.ns_doc_loader_main_division {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.ant-table-wrapper {
  overflow: scroll;


}

.ant-table-wrapper::-webkit-scrollbar {
  display: none;


}

.btn:focus {
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
}


/* loader css start */
/* From Uiverse.io by mrhyddenn */
.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #69717d;
  }

  100% {
    background-color: transparent;
  }
}

/* loader css end */






.ant-table-thead>tr>th {
  text-align: center !important;
  font-size: var(--theme-title-main) !important;
  font-weight: 600 !important;
}

.ant-table-tbody>tr>td {
  text-align: center !important;
  font-size: var(--theme-title-main);
  font-weight: 400;
}

.ant-pagination-options {
  display: block !important;
}



.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  position: relative;
}


.outlet_main_div {
  width: 80%;
}

@media only screen and (max-width:768px) {

  .outlet_main_div {
    width: 100%;
  }
}