.client_forgot_password_main_division {
  display: flex;
  width: 100%;
  padding: 40px 100px;
}

.client_forgot_password_main_first_division {
  width: 50%;
}

.client_forgot_password_img_division {
  width: 100%;
}

.client_forgot_password_img {
  width: 100%;
}

.client_forgot_password_main_second_division {
  width: 50%;
  border: 1px solid var(--doctor-booking-border);
  padding: 30px;
  border-radius: 3px;
}

.client_forgot_password_title_name {
  font-size: var(--title-font);
  font-weight: 600;
}

.client_forgot_password_sub_title_name {
  font-size: var(--theme-description);
  color: var(--theme-table-color);
}

.client_forgot_password_email_field:focus {
  box-shadow: none;
  border-color: var(--theme-main-color) !important;
}

.client_forgot_password_email_field {
  height: 10% !important;
}

.client_forgot_password_email_field_division>label {
  color: var(--theme-table-color);
  font-size: var(--theme-font);
}

.client_forgot_password_forgot_password_division {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  font-size: var(--theme-description);
  color: var(--theme-main-color);
  cursor: pointer;
}

.client_forgot_password_btn_login_division {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  font-size: var(--title-font);
  border: none;
  background-color: var(--theme-main-color);
  color: var(--secondary-color);
  border-radius: 5px;
}

.client_forgot_password_btn_login_division_inactive {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  font-size: var(--title-font);
  border: none;
  background-color: var(--theme-table-color);
  color: var(--secondary-color);
  border-radius: 5px;
}

.client_forgot_password_btn_login_division:hover {
  background-color: var(--theme-main-color);
}

.client_login_forgot_password_division {
  color: var(--theme-main-color);
}


@media only screen and (max-width:1024px) {

  .client_forgot_password_main_division {
    padding: 0px;
  }

}

@media only screen and (max-width:576px) {

  .client_forgot_password_main_division {
    display: block;
  }

  .client_forgot_password_main_second_division,
  .client_forgot_password_main_first_division {
    width: 100%;
    margin: 0px;
  }
}