:root {
    --grid-news: #F9F9F9;
    --feture-back: #f1f1f1;
    --service-back: #edf5fc;
    --home-background-banner: #E5F1FB;
    --botstarp-form: #bbb;
    --home-body-color: #272b41;
    --home-paragraph-title: #3E5463;
    --home-banner-title: #0071DC;
    --border-color: #c4c4c4;
    --doc-login-btn-hover: #09dca4;
    --homepage-testimonial: rgb(48, 8, 8);
}

.coach_background_image_wrapper {
    background-image: url(../../media/homepageimages/hpmepage-bg1.jpg);
    background-position: center 4024px;
    height: 100vh;
}

.coach_homepage_wrapper_1 {
    padding-top: 126px;
    padding-bottom: 64px;
}

.coach_homepage_column {
    padding: 0px 20px;
}

.headline_tag {
    font-size: 5rem;
    color: var(--secondary-color);
}

@media screen and (max-width:768px) {
    .headline_tag {
        font-size: 2.5rem;
    }
}

@media screen and (max-width:576px) {
    .headline_tag {
        font-size: 1.5rem;
    }
}

.headline_work {
    font-size: 1.625rem;
    margin-top: 1.4em;
    color: var(--secondary-color);
    font-size: 400;
}

@media screen and (max-width:576px) {
    .headline_work {
        font-size: 1rem;
        margin-top: 1.4em;
        color: var(--secondary-color);
        font-size: 400;
    }
}

.coach_second_main_div {
    background-color: var(--secondary-color);
    color: var(--black-color);
    padding-top: 128px;
    padding-bottom: 50px;
    text-align: center;
}


.coach_superheadline {
    font-size: .875rem;
    color: var(--theme-main-color);
    font-weight: 500;
    margin-bottom: 28px;
}

.coach_headline_content {
    font-size: 4rem;
    margin-bottom: 32px;
}

.coach_first_column {
    padding: 0px 20px;
    margin-bottom: 2rem;
    justify-content: space-evenly;
}

@media screen and (max-width:768px) {

    .coach_first_column {
        justify-content: center;
    }
}

.soft_skill {
    text-align: center;
    height: 120px;
    width: 120px;
}


.card_1 {
    align-items: center;
    border: none;
    width: 18rem;
    padding: 10px;
    height: 100%;
}

.card_1:hover {
    box-shadow: 0 7px 30px -10px rgba(150, 170, 180, .5);
    transition: 0.3s;
}

.coach_second_sec_div {
    margin-bottom: 2rem;
}

.coach_third_main_div {
    padding: 128px 0px;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width:576px) {

    .coach_third_main_div {
        display: block;
    }

    .coach_left_div {
        flex-direction: column-reverse;
    }

}

.stars_left {
    font-size: 2rem;
    color: yellow;
}

.quote_right {
    font-size: 5rem;
    color: var(--homepage-testimonial);
    text-align: end;
}

.coach_text_left {
    font-size: 3rem;
    font-weight: 500;
    color: var(--homepage-testimonial);
    font-family: "IBM Plex Serif", serif;
    margin-bottom: 1rem;
}

@media screen and (max-width:576px) {
    .coach_text_left {
        font-size: 2rem;
    }
}


.stars_right {
    color: yellow;
    font-size: 2rem;
    text-align: end;
}



.text_right {
    text-align: end;
}

.testinomial_1 {
    font-family: "IBM Plex Serif", serif;
    text-align: justify;
}

.coach_third_main_div {
    padding-bottom: 100px;
}

.coach_fourth_main_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 15px;
}

@media screen and (max-width:576px) {

    .coach_fourth_main_div {
        display: block;
    }
}


.coach_first_div {
    text-align: center;
}

.back_text {
    -webkit-text-stroke: 0.5px var(--theme-main-color);
    color: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 65px;
    font-weight: 800;
    margin: 0 15px;
}

.front_text {
    background: var(--theme-main-color);
    color: transparent;
    background-clip: text;
    font-family: "Poppins", sans-serif;
    font-size: 47px;
    font-weight: 800;
    margin: -66px 0 0;
}

.sub_title {
    font-family: "Poppins", sans-serif;
    color: var(--black-color);
    font-weight: 500;
    font-size: 1rem;
}

.our_program {
    font-family: "IBM Plex Serif", serif;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 900;
}

.program_title {
    font-family: "IBM Plex Serif", serif;
    font-size: 2rem;
    font-weight: 500;
}

.program_para {
    font-family: "IBM Plex Serif", serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
}

@media screen and (max-width:576px) {
    .program_para {
        margin-bottom: 2rem;
    }
}


.coach_sixth_main_div {
    padding: 70px 0px;
    background-color: var(--theme-main-color);
    margin: 30px 0px;
}

.coach_sixth_sub_div {
    text-align: center;
}


.contact_text {
    padding-bottom: 20px;
    color: var(--secondary-color);
}

.contactus_btn {
    background-color: var(--secondary-color);
    border-radius: 10px;
    color: var(--black-color);
    font-size: 1.5rem;
    box-shadow: none;
    padding: 10px;
    width: 15%;
}

.contactus_btn:hover {
    color: var(--secondary-color);
    background-color: var(--black-color);

}

.contactus_btn:focus {
    box-shadow: none;
}


@media screen and (max-width: 570px) {
    .contact_text {
        font-size: 15px;
        color: var(--secondary-color);
        margin-bottom: 30px;
    }

    .contactus_btn {
        width: 40%;
        margin-bottom: 1rem;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    .coach_sixth_sub_div {
        display: block !important;
        text-align: center;
    }

    .contactus_btn {
        width: 40%;
        margin-bottom: 1rem;
    }
}