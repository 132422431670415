.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.6s;
    padding: 40px 100px;
    z-index: 10000;
}

.Header.sticky {
    padding: 5px 100px;
    background: var(--secondary-color);
}

.Header.sticky .header_login_signup_btn {
    background-color: var(--secondary-color) !important;
    color: var(--theme-main-color);
}

.Header.sticky>ul li a {
    color: var(--theme-main-color) !important;
}

.Header .logo {
    position: relative;
    font-weight: 700;
    color: var(--secondary-color);
    text-decoration: none;
    font-size: 2em;
    text-transform: uppercase;
    transition: 0.5s;
}

.Header ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.Header ul li {
    position: relative;
    list-style: none;
}

.Header ul li a {
    position: relative;
    margin: 0 15px;
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: 500;
    transition: 0.5s;
}

.banner {
    position: relative;
    width: 100%;
    height: 100vh;

}

.Header.sticky .logo,
.Header.sticky ul li a {
    color: var(--black-color);
}


.header_active {
    border-bottom: 1px solid var(--theme-main-color);
}

.Header.sticky .header_contact_number,
.header_contact_title {
    color: var(--theme-main-color);
}

.header_contact_number {
    font-weight: 700;
    margin-bottom: 0px;

}

.Header.sticky .header_contact_title {
    color: var(--theme-main-color);
}


.Header.sticky .header_contact_icon {
    color: var(--theme-main-color);
}

.Header.sticky .bell_icon {
    color: var(--theme-main-color);
}


.bell_icon {
    color: var(--secondary-color);
}

.header_contact_title {
    margin-bottom: 0px;
}





.header_contact_profile_main_division {
    display: flex;
    align-items: center;
    justify-content: end;
}



.header_contact_main_division {
    display: flex;
    align-items: center;
}


.header_contact_icon {
    font-size: var(--main-title-large);
}

.header_profile_main_division {
    display: flex;
    align-items: center;
}

@media only screen and (max-width:576px) {
    .header_contact_main_division{
        display: none !important;
    }
}

.header_profile_image_division {
    width: 35px;
    height: 35px;
    margin-right: 15px;
}


.header_profile_image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid var(--black-color);
    padding: 2px;
}

@media only screen and (max-width:1024px) {
    .header_profile_division {
        top: 51px;
    }
}


.header_profile_division {
    position: absolute;
    top: 120px;
    right: 30px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    box-shadow: 0 0 3px var(--doc-header-bs);
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -ms-transform: translateY(0);
    transform: translateY(0);
    z-index: 2;
}


.header_profile_division_header {
    display: flex;
    padding: 10px 15px;
    background-color: var(--secondary-color);
    border-bottom: 1px solid var(--doctor-booking-border);
}

.header_profile_header_profile_image_division {
    width: 35px;
    height: 35px;
    margin: 0px 5px;
}


.header_profile_header_profile_image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}


.header_profile_header_name {
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: capitalize;
    white-space: nowrap;
    color: black;
}

.header_profile_header_postname {
    margin-bottom: 0px;
    color: var(--theme-table-color);
    text-transform: capitalize;
}

.header_profile_list {
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid var(--doctor-booking-border);
    color: black;
}

.header_profile_list:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    transition: 0.2s ease;
}

.doctor_header_notification_title_main {
    padding: 8px;
    background-color: var(--theme-main-color);
}


.doctor_header_notification_title {
    display: flex;
    align-items: center;
}

.doctor_header_notification_title_1 {
    font-size: var(--theme-title-main);
    color: var(--secondary-color);
}

.doctor_header_notification_title_2 {
    background-color: var(--secondary-color);
    color: var(--black-color);
    align-items: center;
    justify-content: center;

}

.doctor_header_notification_title_main_1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    background-color: var(--theme-main-color);
}


.doctor_header_recent {
    color: var(--secondary-color);
}

.doctor_header_recent:hover {
    border: none;
    color: var(--secondary-color);
}

.doctor_header_recent:active {
    border: none;
    color: var(--theme-main-color);
}