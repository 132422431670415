.client_booking_breadcrumb_main_division {
    background-color: var(--black-color);
    padding: 10px 30px;
}

.client_booking_breadcrumb_division {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.client_booking_breadcrumb_title {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    font-family: "poppins", sans-serif;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.client_booking_breadcrumb_title1 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    font-family: "poppins", sans-serif;
    margin: 0px 5px;
    text-transform: capitalize;
}

.client_booking_breadcrumb_title2 {
    color: var(--secondary-color);
    font-size: var(--theme-title);
    font-family: "poppins", sans-serif;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.client_booking_breadcrumb_title_main {
    color: var(--secondary-color);
    font-size: var(--theme-title-font);
    font-family: "poppins", sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

/* client_booking_css_end */


.purpose_of_visit_main_division {
    margin: 0px 0px 10px 0px;
}

.indication_1 {
    color: var(--slot-border-color);
}

.indication_2 {
    color: var(--black-color);
}

.indication_3 {
    color: var(--theme-main-color);
}

.purpose_of_visit_name {
    font-size: var(--theme-title-font);
    font-weight: 700;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.purpose_of_visit_textarea:focus {
    border: 1px solid var(--table-btn-green) !important;
    box-shadow: none !important;
    height: 80px;
}

.purpose_of_visit_textarea {
    border: 1px solid var(--theme-main-color);
}

/* .client_booking_date{
    margin-bottom: 0px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "poppins", sans-serif;
} */
/* .client_booking_day{
    margin-bottom: 0px;
    color: #757575;
    text-transform: capitalize;
    font-family: "poppins", sans-serif;
    font-weight: 500;
} */
/* .client_booking_main_diviison {
    margin-top: 100px;
} */

.client_booking_diviison {
    margin-top: 20px;
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
}

.gnDOZy {
    display: none !important;
}

.hGRYOm:hover:enabled,
.hGRYOm:focus:enabled {
    background-color: var(--theme-main-color) !important;
    color: var(--secondary-color) !important;
    box-shadow: none !important;
}

.hGPzOw {
    background-color: transparent !important;
}

.hGRYOm {
    background-color: transparent !important;
}

.client_booking_week_division {
    display: flex;
    justify-content: space-between;
    padding: 14px 14px;
    border-bottom: 1px solid var(--doctor-booking-border);
}

.client_booking_weekname_day_division {
    text-align: center;
}

.Slxdj {
    background-color: transparent !important;
    border: transparent !important;
}

.jtKTCe {
    display: none !important;
}

.client_booking_week_name_active {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--black-color);
    color: var(--secondary-color);
    width: 110px;
    padding: 5px 0px;
    border-radius: 5px;
    cursor: pointer;
}

.client_booking_week_name {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-family: "poppins", sans-serif;
    width: 110px;
    padding: 5px 0px;
    cursor: pointer;
}

.client_booking_week_name:hover {
    background-color: var(--doctor-booking-border);
}

.client_booking_day_name {
    color: var(--theme-table-color);
    text-transform: uppercase;
    font-weight: 500;
    font-family: "poppins", sans-serif;
}

.client_booking_clear_current_dayslot_button_division {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.client_booking_clear_current_dayslot_button {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    font-weight: 600;
    text-align: center;
}

.client_booking_clear_current_dayslot_button:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.client_booking_time_slot_table_division {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 15px;
    padding: 20px;
    font-size: var(--theme-font);
}

@media only screen and (max-width:576px) {

    .client_booking_time_slot_table_division {
        grid-template-columns: auto !important;
    }
}

@media only screen and (max-width:1024px) {

    .client_booking_time_slot_table_division {
        grid-template-columns: auto auto auto;
    }
}


@media only screen and (max-width:768px) {

    .client_booking_time_slot_table_division {
        grid-template-columns: auto auto auto;
    }

    .purpose_of_visit_main_division {
        margin: 20px 0px;
    }

    .client_booking_date_day {
        margin-top: 30px;
    }

    .client_booking_proceedpay_button {
        padding: 7px 25px !important;
    }
}


@media only screen and (max-width:320px) {

    .client_booking_week_division {
        padding: 15px 4px;
    }

}

.client_booking_time_slots_active {
    text-align: center;
    background-color: var(--black-color);
    color: var(--secondary-color);
    font-family: "poppins", sans-serif;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid var(--slot-border-color);
    cursor: pointer;
    white-space: nowrap;
}

.client_booking_time_slots_active_selected {
    text-align: center;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    font-family: "poppins", sans-serif;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid var(--slot-border-color);
    cursor: pointer;
    white-space: nowrap;
}

.client_booking_time_slots {
    text-align: center;
    background-color: var(--slot-border-color);
    padding: 5px;
    border-radius: 3px;
    border: 1px solid var(--slot-border-color);
    font-family: "poppins", sans-serif;
    cursor: pointer;
    white-space: nowrap;
}


.client_booking_time_slots:hover {
    border: 1px solid var(--doctor-booking-second-border);
    background-color: var(--secondary-color);
}

.client_booking_proceedpay_button_division {
    display: flex;
    justify-content: end;
}


.client_booking_proceedpay_button {
    width: max-content;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    font-size: var(--theme-title-main);
    font-family: "poppins", sans-serif;
    font-weight: 600;
    text-align: center;
    padding: 10px 25px;
}





.client_booking_proceedpay_button:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.client_booking_proceedpay_button:focus {
    box-shadow: none;
}

.requires_feild {
    color: var(--theme-main-color);
    font-size: small;
    padding-left: 10px;
    text-align: center;

}















/* review css start */

.Reviewdetails_back_button {
    background-color: var(--black-color);
    color: var(--secondary-color);
    padding: 5px 20px;
}


.back_icon {
    margin-right: 10px;
    text-align: center;
}



.Reviewdetails_back_button:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.Reviewdetails_main_division {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

}

.Reviewdetails_division {
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
    padding: 10px;
}


.Reviewdetails_summary_name {
    font-size: var(--title-font);
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 0px;
    border-bottom: 1px solid var(--doctor-booking-border);
}



.Reviewdetails_image_division {
    width: 100px;
    margin-right: 20px;
}

.Reviewdetails_image {
    width: 100%;
    border: 1px solid rgb(226, 232, 240);
    border-radius: 10px;
}

.Reviewdetails_mitwank_name {
    font-size: var(--theme-title-main);
    font-weight: 600;
}


.reviewdetails_date_time_fee_division {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
}

.reviewdetails_leftside {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.reviewdetails_total {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.reviewdetails_rightside {
    color: var(--theme-table-color);
    font-size: 15px;
    text-transform: capitalize;
}

.reviewdetails_date_total_division {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 0px 0px;
    border-top: 1px solid var(--doctor-booking-border);
}


/* review css start */










.app_card_main {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.0509803922);
}

.app_clinic_address {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.small_icon {
    font-size: 14px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 14px;
    background: #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book_app_btn {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.book_app_btn:hover {
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
}


.view_btn {
    color: #64748B;
    border: 2px solid #F9FAFB;
    background-color: #F9FAFB;
    text-align: center;
    display: block;
    padding: 6px;
    border-radius: 10px;
}

.view_btn:hover {
    background-color: #eaedf1;
    border-color: #eaedf1;
    color: #64748B;
}

.mail_btn {
    border: 2px solid var(--theme-main-color);
    text-align: center;
    display: block;
    padding: 6px;
    border-radius: 10px;
    width: 100%;
}

.mail_btn:hover {
    background-color: var(--theme-main-color);
    border-color: var(--theme-main-color);
    color: var(--secondary-color);
}

.star_box {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #743A8710;
    color: var(--theme-main-color) !important;
}

.package_name {
    font-weight: 500;
    font-size: 16px;
    color: #6B7280;
}

.package_disc {
    text-align: start;
    color: #6B7280;
}

.package_price {
    font-weight: 600;
    font-size: 22px;
    color: #1f2937;
    font-family: poppins;
}

.package_duration {
    font-weight: 400;
    font-size: 12px;
    color: #9CA3AF;
}