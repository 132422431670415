/* breadcrumb_css_start */
.mitwank_patient_profile_breadcrumb_main_division {
  background-color: var(--black-color);
  padding: 10px 30px;
}

.mitwank_patient_profile_breadcrumb_division {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.mitwank_patient_profile_breadcrumb_title {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  text-transform: capitalize;
  margin-bottom: 0px;
}

.mitwank_patient_profile_breadcrumb_title1 {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  margin: 0px 5px;
  text-transform: capitalize;
}

.mitwank_patient_profile_breadcrumb_title2 {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  text-transform: capitalize;
  margin-bottom: 0px;
}

.mitwank_patient_profile_breadcrumb_title_main {
  color: var(--secondary-color);
  font-size: var(--theme-title-font);
  font-weight: 700;
  text-transform: capitalize;
}



/* breadcrumb_css_end */

/* patient-dashboard css start */

.component_division_mitwank_patient_profile {
  width: 100%;
  padding-left: 15px;
}

.mitwank_patient_profile_main_division {
  border: 1px solid var(--doctor-booking-border);
  border-radius: 5px;
  padding: 25px;
}

/* patient-dashboard pagination css start */

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-item-active a {
  color: var(--theme-main-color) !important;
  padding: none !important;
}

.ant-pagination-item {
  border: none !important;
}

/* patient-dashboard pagination css end */


/* patient-dashboard table css start */


.mitwank_patient_profile_table_division {
  margin-top: 20px;
}

.mitwank_patient_profile_add_prescription_division {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.mitwank_patient_profile_add_prescription_btn {
  background-color: var(--theme-main-color);
  border: none;
  padding: 10px 15px;
  color: var(--secondary-color);
  font-size: var(--title-small-font);
  font-weight: 500;
}

.mitwank_patient_profile_add_prescription_btn:hover {
  color: var(--secondary-color);
}

.mitwank_patient_profile_add_prescription_btn:focus {
  box-shadow: none;
}

.add_medical_records_popuop_header {
  border-bottom: 2px solid var(--doctor-booking-border);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
}

.add_medical_records_popup_body {
  padding: 1rem 1rem;
}

.popup_body_div_two {
  padding: 1rem;
}

.add_medical_records_records_title {
  margin-bottom: 0px;
  font-size: var(--title-font);
  font-weight: 600;
}

.popup_body_name_patient_section {
  display: flex;
  justify-content: space-between;
}

.popup_body_name_title {
  font-size: var(--theme-font);
  margin-bottom: 8px;
}

.popup_body_upload_title {
  font-size: var(--theme-font);
  margin-bottom: 8px;
}

.popup_body_symptoms_title {
  font-size: var(--theme-font);
  margin-bottom: 8px;
}

.popup_body_symptoms_title {
  font-size: var(--theme-font);
  margin-bottom: 8px;
}

.popup_submit_button {
  color: var(--secondary-color) !important;
  background-color: var(--theme-main-color) !important;
  border: none;
}

.popup_input_tags:focus {
  border-color: var(--theme-main-color);
  box-shadow: none;
}

.mitwank_patient_profile_table_confirm_btn {
  border: none;
  border-radius: 3px;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
}

.patient_dashboard_table_reject_btn,
.patient_dashboard_table_confirm_btn {
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  margin-left: 5px;
}

.mitwank_patient_profile_table_Pending_btn {
  background-color: var(--doc-patient-profile-bg-yellow);
  border: none;
  border-radius: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.mitwank_patient_profile_table_edit_btn {
  background-color: var(--table-btn-backgrnd-green);
  border: none;
  color: var(--table-btn-green);
  border-radius: 3px;
}

.mitwank_patient_profile_table_appdate_date {
  font-weight: 400;
  font-size: var(--theme-font);
  white-space: nowrap;
}

.mitwank_patient_profile_table_appdate_time {
  color: var(--theme-main-color);
  font-weight: 500;
  white-space: nowrap;
}

/* dashboard table responsive css end */

@media only screen and (max-width:430px) {
  .mitwank_patient_profile_sidebar_main_division {
    width: 100%;
  }

}

@media only screen and (min-width:431px) and (max-width:767px) {

  .mitwank_patient_profile_mobile {
    display: none;
  }

  .mitwank_patient_profile_table_division {
    overflow-x: scroll;
  }

  .component_division_mitwank_patient_profile {
    width: 100% !important;
  }

}

@media only screen and (min-width:768px) {

  .mitwank_patient_profile_mobile {
    display: none;
  }
}

@media only screen and (max-width:1024px) {

  .mitwank_patient_profile_table_division {
    overflow-x: scroll;
  }

  .mitwank_patient_profile_table_datetime_div {
    width: max-content;
  }
}

@media only screen and (max-width:768px) {

  .mitwank_patient_profile_table_division {
    overflow-x: scroll;
  }

  .mitwank_patient_profile_sidebar_main_division {
    width: 100%;
  }

  .component_division_mitwank_patient_profile {
    padding-left: 0px;
    margin-top: 20px;
  }
}

@media only screen and (min-width:1025px) and (max-width:1439px) {

  .mitwank_patient_profile_table_division {
    overflow-x: scroll;
  }
}

.client_back_button {
  background-color: var(--black-color);
  color: var(--secondary-color);
  padding: 5px 20px;
  margin-bottom: 10px;
}

.client_back_button:hover {
  background-color: var(--theme-main-color);
  color: var(--secondary-color);
}

.patient_dashboard_table_view_btn {
  cursor: pointer;
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  font-weight: 500;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  padding: 3px 5px;
  margin: 0px 3px;
  border: none;
}





.event_card_div {
  display: flex;
  grid-template-rows: auto auto;
  max-width: 600px;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event_card__image_container {
  flex: 0 0 40%;
  position: relative;
}

.event_card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event_card__tags {
  position: absolute;
  top: 10px;
  left: 10px;
}

.event_card__tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin-right: 5px;
}

.event_card__tag__event {
  background-color: #1890ff;
}

.event_card__tag__sports {
  background-color: #52c41a;
}

.event_card__content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.event_card__location,
.event_card__date {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.event_card__icon {
  margin-right: 5px;
}

.event_card__icon__location {
  color: #ff4d4f;
}

.event_card__icon__date {
  color: #8c8c8c;
}

.event_card__location_text {
  color: #ff4d4f;
}

.event_card__date_text {
  color: #8c8c8c;
}

.event_card__title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: start;
}

.event_card__button {
  align-self: flex-start;
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: auto;
}

@media (max-width: 768px) {
  .event_card {
    flex-direction: column;
  }

  .event_card__image_container {
    flex: 0 0 200px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.image_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.image_card {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image_card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .image_grid {
    grid-template-columns: 1fr;
  }
}