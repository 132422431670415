/* breadcrumb_css_start */
.admin_dashboard_breadcrumb_main_division {
  background-color: var(--black-color);
  padding: 10px 30px;
}

.admin_dashboard_breadcrumb_division {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.admin_dashboard_breadcrumb_title {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.admin_dashboard_breadcrumb_title1 {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  margin: 0px 5px;
  text-transform: capitalize;
}

.admin_dashboard_breadcrumb_title2 {
  color: var(--secondary-color);
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.admin_dashboard_breadcrumb_title_main {
  color: var(--secondary-color);
  font-size: var(--theme-title-font);
  font-family: "poppins", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}


/* breadcrumb_css_end */

.appointment_calender_div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0px;
  padding-bottom: 20px;
}

.admin_dashboard_patientappoinment_name {
  font-size: var(--title-font);
  font-weight: 600;
  font-family: "poppins", sans-serif;
  margin-left: 5px;
  margin-top: 5px;


}

.admin_dashboard_main_diviison {
  border-radius: 5px;
  margin-bottom: 20px;
}

.admin_dashboard_patientappoinment_date_division {
  display: flex;
  justify-content: end;

}

.admin_dashboard_patientappoinment_celender {
  border-radius: 5px;
  padding: 7px 15px;
  width: 200px;
  border: 2px solid var(--black-color);
  color: var(--black-color);
}

.admin_dashboard_patientappoinment_celender:focus-visible {
  border: 2px solid var(--theme-main-color) !important;
}

.admin_dashboard_patientappoinment_date_btn {
  background-color: var(--theme-main-color);
  padding: 7px 15px;
  color: var(--secondary-color);
  margin-left: 10px;
  font-weight: 500;
}

.admin_dashboard_patientappoinment_date_btn:hover {
  background-color: var(--theme-main-color);
  color: var(--secondary-color);
}

.admin_dashboard_patient_appointment_table_main_division {
  border: 1px solid var(--doctor-booking-border);
  border-radius: 5px;
}

.admin_dashboard_upcoming_today_btn_divsion {
  display: flex;
  padding: 25px;
  align-items: center;
}

@media only screen and (max-width:576px) {

  .admin_dashboard_upcoming_today_btn_divsion {
    display: block;
  }
}

.admin_dashboard_upcoming_btn_active {
  color: var(--secondary-color);
  background-color: var(--theme-main-color);
  border-radius: 35px;
  padding: 10px 22px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
}

.admin_dashboard_upcoming_btn_active:focus {
  box-shadow: none;
}

.admin_dashboard_upcoming_btn_active:hover {
  color: var(--secondary-color);
}

.admin_dashboard_upcoming_btn {
  color: var(--black-color);
  border-radius: 35px;
  font-family: "poppins", sans-serif;
}

.admin_dashboard_upcoming_btn:hover {
  color: var(--theme-main-color);
}

.admin_dashboard_upcoming_btn:focus {
  box-shadow: none;
}

/* table_css_start */
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-item-active a {
  color: var(--theme-main-color) !important;
  padding: none !important;
}

.ant-pagination-item {
  border: none !important;
}

.admin_dashboard_table_image_name_division {
  display: flex;
  cursor: pointer;
}

.admin_dashboard_table_image {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.admin_dashboard_table_name_id_division {
  margin-left: 10px;
  text-align: start;
}

.admin_dashboard_table_patient_name {
  text-transform: capitalize;
}

.admin_dashboard_table_patient_id {
  color: var(--theme-table-color);
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  text-transform: uppercase;
}

.admin_dashboard_table_appt_date {
  font-size: var(--theme-font);
  font-family: "poppins", sans-serif;
  text-transform: uppercase;
}

.admin_dashboard_table_appt_time {
  font-size: var(--theme-font);
  font-family: "poppins", sans-serif;
  color: var(--theme-main-color);
  text-transform: uppercase;
  white-space: nowrap;
}


.admin_dashboard_tabel_view_btn {
  cursor: pointer;
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  font-weight: 500;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  padding: 3px 5px;
  margin: 0px 3px;
  white-space: nowrap;
}

.admin_dashboard_tabel_view_btn:focus {
  box-shadow: none !important;
}

.admin_dashboard_tabel_accept_btn {
  cursor: pointer;
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  font-weight: 500;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  padding: 3px 5px;
  margin: 0px 3px;
}

.admin_dashboard_tabel_accept_btn:focus {
  box-shadow: none !important;
}

.admin_dashboard_tabel_cancel_btn {
  cursor: pointer;
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  font-weight: 500;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  padding: 3px 5px;
  margin: 0px 3px;
}

.admin_dashboard_tabel_cancel_btn:focus {
  box-shadow: none !important;
}

/* table_css_end */




/* responsive table css :start */





.admin_dashboard_tabel_view_accept_cancel {
  display: flex;
  justify-content: center;
}

.admin_dashboard_tabel_accept_btn_mobile {
  cursor: pointer;
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  font-weight: 500;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  margin: 0px 3px;
  height: 30px;
  border-radius: 5px;
}

.admin_dashboard_tabel_cancel_btn_mobile {
  cursor: pointer;
  font-size: var(--theme-title);
  font-family: "poppins", sans-serif;
  font-weight: 500;
  background-color: var(--table-btn-backgrnd-blue);
  color: var(--black-color);
  margin: 0px 3px;
  height: 30px;
  border-radius: 5px;
}

/* appointments-card-mobile-css-end */

/* responsive table css end */

@media only screen and (max-width:768px) {
  .slidebar_and_component {
    padding: 30px 10px !important;
    display: block !important;
  }


}






@media only screen and (min-width:768px) and (max-width:1024px) {


  .admin_dashboard_time_date_div {
    width:max-content;
  }

}


@media only screen and (min-width:1024px) and (max-width:1444px) {

  .admin_dashboard_time_date_div {
    width:max-content;
  }
}



/* card notification css start */

.admin_dashboard_notification_main_div {
  display: flex;
  justify-content: space-between;
}

.admin_dashboard_notification_patient_name {
  font-size: var(--theme-font);
  font-weight: 600;
  letter-spacing: 0.05em;
  font-family: "poppins", sans-serif;
}

.admin_dashboard_notification_booking_time {
  font-size: var(--theme-font);
  font-weight: 400;
  letter-spacing: 0.05em;
  font-family: "poppins", sans-serif;
  color: var(--theme-main-color);
}

.admin_dashboard_notification_date {
  font-size: var(--theme-font);
  font-weight: 400;
  letter-spacing: 0.05em;
  font-family: "poppins", sans-serif;
}

.admin_dashboard_notification_patient_image {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}


/* card notification css end */

.delete_icon {
  background-color: var(--table-btn-backgrnd-red);
  color: var(--theme-main-color);
  width: 35px;
  height: 35px;
  padding: 8px;
  border-radius: 3px;
}