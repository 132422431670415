.mitwank_login_main_division {
    display: flex;
    width: 100%;
    padding: 40px 150px;
}

.mitwank_login_main_first_division {
    width: 50%;
}

.mitwank_login_img_division {
    width: 100%;
}

.mitwank_login_img {
    width: 100%;
}

.mitwank_login_main_second_division {
    width: 50%;
    border: 1px solid var(--doctor-booking-border);
    padding: 30px;
    border-radius: 3px;
}

.mitwank_login_title_name {
    font-size: var(--title-font);
    font-weight: 600;
}

.mitwank_login_email_field:focus {
    box-shadow: none;
    border-color: var(--doctor-booking-second-border);
}

.mitwank_login_email_field_division>label {
    color: var(--theme-table-color);
    font-size: var(--theme-font);
}

.mitwank_login_forgot_password_division {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    font-size: var(--theme-description);
    color: var(--theme-main-color);
    cursor: pointer;
}

.mitwank_login_btn_login_division {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    font-size: var(--theme-title-font);
    border: none;
    background-color: var(--theme-main-color);
    color: var(--secondary-color);
    border-radius: 5px;
}



.mitwank_login_new_account_title_division {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.mitwank_login_new_account_title {
    font-size: var(--theme-font);
    margin-right: 5px;
    color: var(--client-upload-image-bs);
}

.mitwank_login_new_account_title_register {
    font-size: var(--theme-font);
    color: var(--theme-main-color);
}


@media only screen and (max-width:768px) {

    .mitwank_login_main_division {
        padding: 0px;
        margin-top: 40px;
    }
}

@media only screen and (max-width:430px) {

    .mitwank_login_main_division {
        display: block;
    }

    .mitwank_login_main_first_division {
        width: 100%;
    }

    .mitwank_login_main_second_division {
        width: 100%;
    }
}