/* sidebar-css-start */
@media only screen and (max-width:768px) {
    .sidebar_main_division {
        width: 100% !important;
    }

}

.sidebar_division {
    border: 1px solid var(--doctor-booking-border);
    border-radius: 5px;
}

/* sidebar-css-end */