.Popup_main_division {
   padding: 20px;
   background-color: var(--theme-main-color);
   border-radius: 30px;

}

.Popup_main_division_rejected {
   padding: 20px;
   background-color: var(--theme-main-color);
   border-radius: 30px;

}

.popup_checked {
   color: var(--secondary-color);
}

.popup_check_icon {
   color: var(--theme-main-color);
   text-align: center;
   z-index: 1;
}

.popup_circle_main_div {
   display: flex;
   justify-content: center;
}

.popup_circle {
   height: max-content;
   width: max-content;
   background-color: var(--secondary-color);
   border-radius: 50%;
   padding: 14px;
}

.popup_circle_rejected {
   background-color: var(--secondary-color);
   border-radius: 50%;
   padding: 14px 18px;
}

.popup_circle_right {
   color: var(--theme-main-color);
}

.popup_circle_rejected_icon {
   color: var(--theme-main-color);
   margin: 0px 10px 0px 10px;
}

.popup_first_division {
   text-align: center;
}

.popup_text {
   margin-top: 5%;
   text-align: center;
   color: var(--secondary-color);
}

.popup_button {
   display: flex;
   justify-content: center;
   margin-top: 5%;
}

.popup_button_rejected {
   display: flex;
   justify-content: center;
   margin-top: 5%;
}

.popup_button_dashboard {
   border-radius: 30px;
   width: 45%;
   padding: 12px;
   color: var(--theme-main-color);
   background-color: var(--secondary-color);
   font-weight: 700;
   border: none;
   background-color: var(--secondary-color);
}

.popup_button_dashboard_rejected {
   border-radius: 30px;
   width: 50%;
   padding: 8px;
   color: var(--theme-main-color);
   background-color: var(--secondary-color);
   font-weight: 700;
   border: none;
   background-color: var(--secondary-color);
}