.contact_main_div {
  padding-bottom: 64px;
  padding-top: 128px;
}


.about_main_div {
  background-image: url(../../media/about/about_back.jpg);
  padding: 100px 0px;
  background-size: cover;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_title {
  font-family: poppins;
  font-size: 36px;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.contact_para {
  font-family: poppins;
  color: var(--secondary-color);
  font-size: 1.625rem;
}

@media screen and (max-width:576px) {
  .contact_tag_div {
    padding-top: 2rem;
  }

  .visit_us {
    padding-top: 2rem;
  }

  .follow_us {
    padding-top: 2rem;
  }
}

.contact_tag_main {
  display: flex;
}

.submit_btn {
  background-color: var(--theme-main-color);
  color: var(--secondary-color);
  height: 3.5rem;
}



.contact_main_div {
  align-items: center !important;
}



.call_us {
  font-weight: 600;
  font-size: 1.25rem;
}

.contact_number {
  color: grey;
  font-weight: 600;
  font-size: 2.125rem;
}

.visit_us {
  font-size: 1rem;
  font-weight: 400;
}

.color_us {
  color: grey;
}

.facebook_icon {
  color: var(--secondary-color) !important;
  font-size: 25px;
  background-color: black;
  text-align: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.facebook_icon:hover {
  color: var(--secondary-color);
}

.social_icon {
  display: flex;
  justify-content: space-between;
}