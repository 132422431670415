.mitwankmypatient_breadcrumb_main_division {
   background-color: var(--black-color);
   padding: 10px 30px;
}

.mitwankmypatient_breadcrumb_division {
   display: flex;
   align-items: center;
   margin-bottom: 5px;
}

.mitwankmypatient_breadcrumb_title {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   text-transform: capitalize;
   margin-bottom: 0px;
}

.mitwankmypatient_breadcrumb_title1 {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   margin: 0px 5px;
   text-transform: capitalize;
}

.mitwankmypatient_breadcrumb_title2 {
   color: var(--secondary-color);
   font-size: var(--theme-title);
   text-transform: capitalize;
   margin-bottom: 0px;
}

.mitwankmypatient_breadcrumb_title_main {
   color: var(--secondary-color);
   font-size: var(--theme-title-font);
   font-weight: 700;
   text-transform: capitalize;
}


/* breadcrumb_css_end */

.new_card_main {
   padding: 24px;
   box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.0509803922);
   border: 1px solid var(--doctor-booking-border);
   border-radius: 10px;
   margin: 0 0 15px;
}

.image_div {
   height: 65px;
   width: 65px;
   margin-right: 10px;
   border-radius: 10px;
   object-fit: contain;
}

.new_card_client_id {
   color: var(--theme-main-color);
   font-size: var(--theme-title);
   font-weight: 500;
}

.new_card_client_name {
   font-weight: 800;
   text-transform: capitalize;
   font-size: 15px;
}

.age_title {
   font-size: var(--theme-title);
   margin-right: 10px;
   text-transform: capitalize;
}

.gender_title {
   margin-left: 10px;
   font-size: var(--theme-title);
}

.other_info {
   background: #f1f5f9;
   padding: 15px;
   border-radius: 10px;
}

.more_details_btn {
   font-size: 15px;
   border-radius: 10px;
   color: var(--secondary-color);
   background: var(--theme-main-color);
   white-space: nowrap;
}

.more_details_btn:hover {
   color: var(--secondary-color);
}

.search-field {
   position: relative;
}

.search-icon {
   position: absolute;
   right: 15px;
   top: 50%;
   transform: translateY(-50%);
   color: var(--theme-table-color);
}

.view-toggle-buttons {
   display: flex;
   gap: 10px;
   margin-bottom: 20px;
}

.view-toggle-button {
   border: none;
   background: none;
   cursor: pointer;
   font-size: 20px;
   color: var(--theme-table-color);
}

.view-toggle-button.active {
   color: var(--theme-main-color);
}

.search-field {
   display: flex;
   align-items: center;
   position: relative;
}

.search-icon {
   background-color: var(--theme-main-color);
   color: var(--secondary-color);
   padding: 10px;
   border-radius: 0 5px 5px 0;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
}

.search-field input {
   border-radius: 5px 0 0 5px;
   border: 1px solid var(--theme-main-color);
   padding: 10px;
   width: 100%;
   outline: none;
}





.modal-overlay.opening {
   animation: fadeIn 0.3s forwards;
}

.modal-overlay.closing {
   animation: fadeOut 0.3s forwards;
}

.modal-content.opening {
   animation: scaleIn 0.3s forwards;
}

.modal-content.closing {
   animation: scaleOut 0.3s forwards;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@keyframes fadeOut {
   from {
      opacity: 1;
   }

   to {
      opacity: 0;
   }
}

@keyframes scaleIn {
   from {
      transform: scale(0.9);
   }

   to {
      transform: scale(1);
   }
}

@keyframes scaleOut {
   from {
      transform: scale(1);
   }

   to {
      transform: scale(0.9);
   }
}